@import url(https://fonts.googleapis.com/css2?family=Public+Sans:wght@400;500;700&display=swap);
* {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*:not(input, textarea) {
  -webkit-user-select: none;
  user-select: none;
}

input.white-caret {
  caret-color: white;
}

/* Change autofill background color */
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px #313338 inset !important;
  -webkit-text-fill-color: white !important;
  caret-color: white;
}

input:-moz-autofill {
  box-shadow: 0 0 0 30px #313338 inset !important;
  -moz-text-fill-color: white !important;
  caret-color: white;
}

input:-ms-autofill {
  box-shadow: 0 0 0 30px #313338 inset !important;
  -ms-text-fill-color: white !important;
  caret-color: white;
}

/* This rule ensures autofill styling works across different browsers */
input:-internal-autofill-selected {
  background-color: #313338 !important;
  background-clip: content-box !important;
  -webkit-text-fill-color: white !important;
  caret-color: white;
}

/* hide or disable the google logo, footer, copyright on google maps */
.gm-style-cc {
  display: none !important;
}

.gm-style a[href^="https://maps.google.com/maps"]
{
  display: none !important;
}

html {
  height: 100%;
  width: 100%;
}

body {
  height: 100%;
  width: 100%;
  font-family: "Public Sans", sans-serif;
  color: var(--secondaryColor);
}

.horizontal-ruler-line-grey {
  border-bottom: 2px solid #f1f1f1;
}

input {
  outline: none;
}

/* Variable declarations */
:root {
  --spacing: 8px;
  --primaryColor: #4362ea;
  --secondaryColor: #000000;
  --tertiaryColor: #404040;
  --quaternaryColor: #868686;
}

.user-select-none {
  -webkit-user-select: none;
          user-select: none;
}

.cursor-pointer {
  cursor: pointer;
}

.pointer-events-none {
  pointer-events: none;
}

/* font family global classes*/

.font-family-public-sans {
  font-family: "Public Sans", sans-serif;
}

.font-family-space-grotesk {
  font-family: "Space Grotesk", sans-serif;
}

/* font size global classes */
.letter-spacing-large {
  letter-spacing: 0.4px;
}

.font-size-smaller.letter-spacing-large {
  letter-spacing: 0.4px;
}

.font-size-smaller {
  font-size: 10px;
}

.font-size-small {
  font-size: 12px;
}

.font-size-medium {
  font-size: 14px;
}

.font-size-default {
  font-size: 16px;
}

.font-size-large {
  font-size: 18px;
}

.font-size-larger {
  font-size: 20px;
}

/*  font color global classes  */
.font-color-white {
  color: white;
}

.font-color-dark {
  color: #313338;
}

.font-color-primary {
  color: var(--primaryColor);
}

.font-color-secondary {
  color: var(--secondaryColor);
}

.font-color-tertiary {
  color: var(--tertiaryColor);
}

.font-color-quaternary {
  color: var(--quaternaryColor);
}

.font-color-black {
  color: black;
}

.font-color-red {
  color: #e64539;
}

.font-color-grey {
  color: #f1f1ff;
}

.font-weight-300 {
  font-weight: 300;
}

.font-weight-400 {
  font-weight: 400;
}

.font-weight-normal {
  font-weight: normal;
}

.text-overflow-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.white-space-no-wrap {
  white-space: nowrap;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-uppercase {
  text-transform: uppercase;
}

.letter-spacing-4-percentage {
  letter-spacing: 0.6px;
}

/*  visibility  global classes  */
.visibility-visible {
  visibility: visible;
}

.visibility-hidden {
  visibility: hidden;
}

.background-color-lite-grey {
  background-color: #e5e5e5;
}

.background-color-lite-grey-50-percentage {
  background: rgba(229, 229, 229, 0.8);
}

.background-color-lite-grey-10-percentage {
  background: rgba(229, 229, 229, 0.1);
}

.background-color-grey-C2C2C2 {
  background-color: #c2c2c2;
}

.background-white {
  background: white;
}

.background-linear-gradient-default {
  background: linear-gradient(139.67deg, #00a000 24.46%, #14f27a 100%);
}

.background-transparent {
  background: transparent;
}

.background-color-tertiary {
  background: var(--tertiaryColor);
}

.background-color-primary {
  background-color: var(--primaryColor);
}

.background-color-quaternary {
  background-color: var(--quaternaryColor);
}

.background-color-red {
  background: #e64539;
}

.background-color-grey {
  background: #f1f1f1;
}

.background-color-secondary {
  background-color: #f5f5f5;
}

.background-color-black {
  background-color: #000;
}

.background-color-transparent {
  background-color: transparent;
}

/*  border-radius  global classes  */
.border-radius-default {
  border-radius: 8px;
}

.border-radius-2px {
  border-radius: 2px;
}

.border-radius-0 {
  border-radius: 0px;
}

.border-bottom-radius-default {
  border-radius: 0 0 8px 8px;
}

.border-top-radius-default {
  border-radius: 8px 8px 0 0;
}

.border-radius-100-percentage {
  border-radius: 100%;
}

.border-radius-50-percentage {
  border-radius: 50%;
}

.border-radius-25-percentage {
  border-radius: 25%;
}

.border-red {
  border: 1px solid #e64539;
}

.border-primaryColor {
  border: 1px solid var(--primaryColor);
}

.border-tertiaryColor {
  border: 1px solid var(--tertiaryColor);
}

.border-secondary {
  border: 1px solid var(--secondaryColor);
}

.border-bottom-1px {
  border-bottom: 1px solid #f1f1f1;
}

.border-1px-e5e5e5 {
  border: 1px solid #e5e5e5;
}

.box-shadow-default {
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 5px rgba(0, 0, 0, 0.12),
    0px 2px 2px rgba(0, 0, 0, 0.15);
}

.box-shadow-none {
  box-shadow: none;
}

/* list style*/
.list-style-none {
  list-style: none;
}

/* global padding classes*/
.padding-smaller {
  padding: calc(var(--spacing) * 0.25);
}

.padding-small {
  padding: calc(var(--spacing) * 0.5);
}

.padding-default {
  padding: calc(var(--spacing) * 1);
}

.padding-medium {
  padding: calc(var(--spacing) * 1.5);
}

.padding-large {
  padding: calc(var(--spacing) * 2);
}

.padding-larger {
  padding: calc(var(--spacing) * 3);
}

/*global padding top spacing classes*/
.padding-top-smaller {
  padding-top: calc(var(--spacing) * 0.25);
}

.padding-top-small {
  padding-top: calc(var(--spacing) * 0.5);
}

.padding-top-default {
  padding-top: calc(var(--spacing) * 1);
}

.padding-top-medium {
  padding-top: calc(var(--spacing) * 1.5);
}

.padding-top-large {
  padding-top: calc(var(--spacing) * 2);
}

.padding-top-larger {
  padding-top: calc(var(--spacing) * 3);
}

/*global padding bottom spacing classes*/
.padding-bottom-smaller {
  padding-bottom: calc(var(--spacing) * 0.25);
}

.padding-bottom-small {
  padding-bottom: calc(var(--spacing) * 0.5);
}

.padding-bottom-default {
  padding-bottom: calc(var(--spacing) * 1);
}

.padding-bottom-medium {
  padding-bottom: calc(var(--spacing) * 1.5);
}

.padding-bottom-large {
  padding-bottom: calc(var(--spacing) * 2);
}

.padding-bottom-larger {
  padding-bottom: calc(var(--spacing) * 3);
}

/*global padding right spacing classes*/
.padding-right-smaller {
  padding-right: calc(var(--spacing) * 0.25);
}

.padding-right-small {
  padding-right: calc(var(--spacing) * 0.5);
}

.padding-right-default {
  padding-right: calc(var(--spacing) * 1);
}

.padding-right-medium {
  padding-right: calc(var(--spacing) * 1.5);
}

.padding-right-large {
  padding-right: calc(var(--spacing) * 2);
}

.padding-right-larger {
  padding-right: calc(var(--spacing) * 3);
}

/*-- global padding left spacing classes --*/
.padding-left-smaller {
  padding-left: calc(var(--spacing) * 0.25);
}

.padding-left-small {
  padding-left: calc(var(--spacing) * 0.5);
}

.padding-left-default {
  padding-left: calc(var(--spacing) * 1);
}

.padding-left-medium {
  padding-left: calc(var(--spacing) * 1.5);
}

.padding-left-large {
  padding-left: calc(var(--spacing) * 2);
}

.padding-left-larger {
  padding-left: calc(var(--spacing) * 3);
}

/*global padding vertical spacing classes*/
.padding-vertical-smaller {
  padding: calc(var(--spacing) * 0.25) 0;
}

.padding-vertical-small {
  padding: calc(var(--spacing) * 0.5) 0;
}

.padding-vertical-default {
  padding: calc(var(--spacing) * 1) 0;
}

.padding-vertical-medium {
  padding: calc(var(--spacing) * 1.5) 0;
}

.padding-vertical-large {
  padding: calc(var(--spacing) * 2) 0;
}

.padding-vertical-larger {
  padding: calc(var(--spacing) * 3) 0;
}

/*global padding horizontal spacing classes*/
.padding-horizontal-smaller {
  padding: 0 calc(var(--spacing) * 0.25);
}

.padding-horizontal-small {
  padding: 0 calc(var(--spacing) * 0.5);
}

.padding-horizontal-default {
  padding: 0 calc(var(--spacing) * 1);
}

.padding-horizontal-medium {
  padding: 0 calc(var(--spacing) * 1.5);
}

.padding-horizontal-large {
  padding: 0 calc(var(--spacing) * 2);
}

.padding-horizontal-larger {
  padding: 0 calc(var(--spacing) * 3);
}

/* */

/* global margin classes*/
.margin-auto {
  margin: auto;
}

.margin-vertical-auto {
  margin: auto 0;
}

.margin-horizontal-auto {
  margin: 0 auto;
}

.margin-smaller {
  margin: calc(var(--spacing) * 0.25);
}

.margin-small {
  margin: calc(var(--spacing) * 0.5);
}

.margin-default {
  margin: calc(var(--spacing) * 1);
}

.margin-medium {
  margin: calc(var(--spacing) * 1.5);
}

.margin-large {
  margin: calc(var(--spacing) * 2);
}

.margin-larger {
  margin: calc(var(--spacing) * 3);
}

/*global margin top spacing classes*/
.margin-top-smaller {
  margin-top: calc(var(--spacing) * 0.25);
}

.margin-top-small {
  margin-top: calc(var(--spacing) * 0.5);
}

.margin-top-default {
  margin-top: calc(var(--spacing) * 1);
}

.margin-top-medium {
  margin-top: calc(var(--spacing) * 1.5);
}

.margin-top-large {
  margin-top: calc(var(--spacing) * 2);
}

.margin-top-larger {
  margin-top: calc(var(--spacing) * 3);
}

/*global margin bottom spacing classes*/
.margin-bottom-smaller {
  margin-bottom: calc(var(--spacing) * 0.25);
}

.margin-bottom-small {
  margin-bottom: calc(var(--spacing) * 0.5);
}

.margin-bottom-default {
  margin-bottom: calc(var(--spacing) * 1);
}

.margin-bottom-medium {
  margin-bottom: calc(var(--spacing) * 1.5);
}

.margin-bottom-large {
  margin-bottom: calc(var(--spacing) * 2);
}

.margin-bottom-larger {
  margin-bottom: calc(var(--spacing) * 3);
}

/*global margin right spacing classes*/
.margin-right-smaller {
  margin-right: calc(var(--spacing) * 0.25);
}

.margin-right-small {
  margin-right: calc(var(--spacing) * 0.5);
}

.margin-right-default {
  margin-right: calc(var(--spacing) * 1);
}

.margin-right-medium {
  margin-right: calc(var(--spacing) * 1.5);
}

.margin-right-large {
  margin-right: calc(var(--spacing) * 2);
}

.margin-right-larger {
  margin-right: calc(var(--spacing) * 3);
}

/*-- global margin left spacing classes --*/
.margin-left-smaller {
  margin-left: calc(var(--spacing) * 0.25);
}

.margin-left-small {
  margin-left: calc(var(--spacing) * 0.5);
}

.margin-left-default {
  margin-left: calc(var(--spacing) * 1);
}

.margin-left-medium {
  margin-left: calc(var(--spacing) * 1.5);
}

.margin-left-large {
  margin-left: calc(var(--spacing) * 2);
}

.margin-left-larger {
  margin-left: calc(var(--spacing) * 3);
}

/*global margin vertical spacing classes*/
.margin-vertical-smaller {
  margin: calc(var(--spacing) * 0.25) 0;
}

.margin-vertical-small {
  margin: calc(var(--spacing) * 0.5) 0;
}

.margin-vertical-default {
  margin: calc(var(--spacing) * 1) 0;
}

.margin-vertical-medium {
  margin: calc(var(--spacing) * 1.5) 0;
}

.margin-vertical-large {
  margin: calc(var(--spacing) * 2) 0;
}

.margin-vertical-larger {
  margin: calc(var(--spacing) * 3) 0;
}

/*global margin horizontal spacing classes*/
.margin-horizontal-smaller {
  margin: 0 calc(var(--spacing) * 0.25);
}

.margin-horizontal-small {
  margin: 0 calc(var(--spacing) * 0.5);
}

.margin-horizontal-default {
  margin: 0 calc(var(--spacing) * 1);
}

.margin-horizontal-medium {
  margin: 0 calc(var(--spacing) * 1.5);
}

.margin-horizontal-large {
  margin: 0 calc(var(--spacing) * 2);
}

.margin-horizontal-larger {
  margin: 0 calc(var(--spacing) * 3);
}

p {
  margin: 0;
}

a {
  color: #00a000 !important;
  text-decoration: none;
}

a:hover {
  color: #00a000 !important;
  text-decoration: underline;
}

button {
  border: none;
}

.border-none {
  border: none;
}

.white-space-nowrap {
  white-space: nowrap;
}

.text-overflow-ellipsis {
  text-overflow: ellipsis;
}

.text-align-center {
  text-align: center;
}

.text-align-right {
  text-align: right;
}

.text-align-left {
  text-align: left;
}

.text-underline {
  text-decoration: underline;
  text-underline-offset: 4px;
}

.text-transform-capitalize {
  text-transform: capitalize;
}

.text-transform-uppercase {
  text-transform: uppercase;
}

.text-transform-lowercase {
  text-transform: lowercase;
}

.input-error-border {
  border: 1px solid #e64539;
}

.input-text-clip {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -webkit-appearance: textfield;
  appearance: textfield;
}

/* display positions global classes*/
.z-index-1 {
  z-index: 1;
}

.z-index-2 {
  z-index: 2;
}

.position-fixed {
  position: fixed;
}

.position-absolute {
  position: absolute;
}

.position-relative {
  position: relative;
}

.position-sticky {
  position: -webkit-sticky;
  position: sticky;
}

.top-0 {
  top: 0;
}

.bottom-0 {
  bottom: 0;
}

.right-0 {
  right: 0;
}

.left-0 {
  left: 0;
}

.absolute-center-self-vertically {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.position-absolute-center-self {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.position-fixed-center-self {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.absolute-center-self-horizontally {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

/* ------------- display flex global classes ------- */
.display-flex {
  display: flex;
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

.opacity-zero {
  opacity: 0;
}

.width-10-percentage {
  width: 10%;
}

.width-20-percentage {
  width: 20%;
}

.width-30-percentage {
  width: 30%;
}

.width-40-percentage {
  width: 40%;
}

.width-50-percentage {
  width: 50%;
}

.width-60-percentage {
  width: 60%;
}

.width-70-percentage {
  width: 70%;
}

.width-80-percentage {
  width: 80%;
}

.width-90-percentage {
  width: 90%;
}

.inherit-parent-width {
  width: 100%;
}

.height-inherit {
  height: inherit;
}

.width-inherit {
  width: inherit;
}

.width-fit-content {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.max-width-100-percentage {
  max-width: 100%;
}

.max-width-500px {
  max-width: 500px;
}

.max-width-588px {
  max-width: 588px;
}

.max-width-630px {
  max-width: 630px;
}

.max-width-767px {
  max-width: 767px;
}

.height-10-percentage {
  height: 10%;
}

.height-20-percentage {
  height: 20%;
}

.height-30-percentage {
  height: 30%;
}

.height-40-percentage {
  height: 40%;
}

.height-50-percentage {
  height: 50%;
}

.height-60-percentage {
  height: 60%;
}

.height-70-percentage {
  height: 70%;
}

.height-80-percentage {
  height: 80%;
}

.height-90-percentage {
  height: 90%;
}

.inherit-parent-width {
  width: 100%;
}

.inherit-parent-height {
  height: 100%;
}

.min-height-100-percentage {
  min-height: 100%;
}

.max-height-90-percentage {
  max-height: 90%;
}

.height-fit-content {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.width-fit-content {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.height-auto {
  height: auto;
}

.height-48px {
  min-height: 48px;
}

.flex-align-items-end {
  display: flex;
  align-items: flex-end;
}

.flex-justify-content-end {
  display: flex;
  justify-content: flex-end;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-start {
  display: flex;
  align-items: flex-start;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-justify-content-space-between {
  display: flex;
  justify-content: space-between;
}

.flex-justify-content-space-around {
  display: flex;
  justify-content: space-around;
}

.flex-justify-content-space-evenly {
  display: flex;
  justify-content: space-evenly;
}

.flex-direction-column {
  flex-direction: column;
}

.flex-direction-row {
  display: flex;
  flex-direction: row;
}

.flex-direction-row-reverse {
  display: flex;
  flex-direction: row-reverse;
}

.flex-place-children-page-center {
  display: flex;
  align-content: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.flex-start-place-children-vertically {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

.flex-center-children-vertically {
  display: flex;
  align-items: center;
}

.flex-center-children-horizontally {
  display: flex;
  justify-content: center;
}

.flex-justify-content-center {
  display: flex;
  justify-content: center;
}

.flex-align-items-center {
  align-items: center;
}

.fixed-header-padding {
  padding-top: 80px;
}

/* Ripple effect */
.ripple-element {
  position: relative;
  transition: background 400ms;
  overflow: hidden;
  cursor: pointer;
}

.ripple-span.ripples {
  position: absolute;
  border-radius: 50%;
  transform: scale(0);
  height: inherit;
  overflow: hidden;
  animation: ripples 600ms linear;
  border-radius: 50%;
  background-color: rgba(143, 143, 143, 0.397);
}

@keyframes ripples {
  to {
    transform: scale(4);
    opacity: 0;
  }
}

/* shimmer effect */
.shimmer {
  animation-duration: 2.2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: shimmer;
  animation-timing-function: linear;
  background: rgb(238, 238, 238);
  background: linear-gradient(
    72deg,
    rgba(238, 238, 238, 0.9821564749385534) 8%,
    rgba(252, 252, 252, 0.9653025423542837) 18%,
    rgba(240, 238, 238, 0.9681115311183286) 33%
  );
  background-size: 1200px 100%;
  cursor: wait;
}

/**
 * ==============================================
 * Dot Flashing
 * ==============================================
 */
.dot-flashing {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: var(--primaryColor);
  color: var(--primaryColor);
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}

.dot-flashing::before,
.dot-flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-flashing::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: var(--primaryColor);
  color: var(--primaryColor);
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 0s;
}

.dot-flashing::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: var(--primaryColor);
  color: var(--primaryColor);
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dotFlashing {
  0% {
    background-color: var(--primaryColor);
  }

  50%,
  100% {
    background-color: #ebe6ff;
  }
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-scroll {
  overflow: scroll;
}

.overflow-auto {
  overflow: auto;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.overflow-x-auto {
  overflow-x: auto;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.hide-scrollbar {
  scrollbar-color: transparent;
  scrollbar-width: 0;
}

.overflow-x-scroll {
  overflow-x: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.overflow-scroll::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.remaining-body-height {
  height: calc(100% - 64px);
}

.remaining-body-height_-128px {
  height: calc(100% - 128px);
}

.remaining-height-actions-header {
  height: calc(100% - 73px);
}

.object-fit-cover {
  object-fit: cover;
}

@keyframes shimmer {
  0% {
    background-position: -1200px 0;
  }

  100% {
    background-position: 1200px 0;
  }
}

.line-clamp {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.dot-4px {
  height: 4px;
  width: 4px;
  background-color: #404040;
  border-radius: 50%;
  display: inline-block;
}

.dot-4px-quaternary {
  height: 4px;
  width: 4px;
  background-color: var(--quaternaryColor);
  border-radius: 50%;
  display: inline-block;
}

.height-width-56px {
  height: 56px;
  width: 56px;
}

.background-white-half {
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 50%
  );
}

.background-white-80-percentage {
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 40%
  );
}

.rotate-180deg {
  transform: rotate(180deg);
}

.background-color-FFF4EA {
  background-color: #fff4ea;
}

.font-color-CB6F19 {
  color: #cb6f19;
}

.carousel .control-dots {
  bottom: -14px !important;
}

.carousel .control-arrow {
  opacity: 1 !important;
}

.carousel .control-dots .dot {
  box-shadow: none !important;
}

.carousel .control-prev.control-arrow {
  top: 50%;
  width: 30px;
  height: 40px;
  left: 24px;
  bottom: 50%;
}

.carousel .control-next.control-arrow {
  top: 50%;
  width: 30px;
  height: 40px;
  right: 24px;
  bottom: 50%;
}

.carousel .control-prev.control-arrow:before {
  border-right: 8px solid white !important;
}

.carousel .control-next.control-arrow:before {
  border-left: 8px solid white !important;
}

.qr-loader {
  margin-top: 0.2em;
  margin-bottom: 0.2em;
  text-indent: -9999em;
  width: 1.3em;
  height: 1.3em;
  border-radius: 50%;
  background: #4362ea;
  background: linear-gradient(to right, #4362ea 10%, rgba(0, 200, 128, 0) 42%);
  position: relative;
  animation: load3 1.4s infinite linear;
  transform: translateZ(0);
}

.qr-loader:before {
  width: 50%;
  height: 50%;
  background: #4362ea;
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
}

.qr-loader:after {
  background: white;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: "";
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
@keyframes load3 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.adaptive-border-radius-default {
  border-radius: 8px;
}

.adaptive-padding-main {
  padding: 24px;
}

.adaptive-parent-height {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.adaptive-login-section-width-height {
  width: 30%;
}

.adaptive-carousel-section-width-height {
  width: 70%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.adaptive-carousel-image1-height {
  height: 100%;
}

.adaptive-carousel-image2-height {
  height: 100%;
}

.adaptive-carousel-width {
  width: 90%;
}

.adaptive-carousel-height {
  height: 75%;
}

.adaptive-flex {
  display: flex;
}

.adaptive-width-50-percentage {
  width: 50%;
}
.p1-adaptive-font-size {
  font-size: 20px;
}

.p-adaptive-font-size {
  font-size: 16px;
}

.carousel .control-dots {
  bottom: 0px !important;
}

.adaptive-map-width {
  width: 55%;
}

@media only screen and (max-width: 767px) {
  .adaptive-parent-height {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }

  .adaptive-border-radius-default {
    border-radius: 0px;
  }

  .adaptive-padding-main {
    padding: 0px;
  }

  .adaptive-flex {
    display: block;
  }

  .adaptive-map-width {
    width: 100%;
  }
  .adaptive-padding-top {
    padding-top: 24px;
  }

  .p1-adaptive-font-size {
    font-size: 18px;
  }
  .p-adaptive-font-size {
    font-size: 14px;
  }

  .adaptive-login-section-width-height {
    width: 100%;
  }

  .adaptive-width-50-percentage {
    width: 100%;
  }

  .adaptive-carousel-section-width-height {
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    min-height: -webkit-fit-content;
    min-height: -moz-fit-content;
    min-height: fit-content;
  }

  .adaptive-align {
    justify-content: center;
  }

  .adaptive-carousel-width {
    width: 90%;
  }

  .adaptive-map-width {
    width: 100%;
  }

  .adaptive-carousel-height {
    height: 100%;
  }

  .adaptive-carousel-image2-height {
    height: 320px;
  }

  .adaptive-carousel-image1-height {
    height: 470px !important;
  }
}

@media only screen and (max-width: 1440px) and (min-width: 1400px) and (min-height: 700px) {
  .adaptive-carousel-width {
    width: 90%;
  }
  .adaptive-carousel-height {
    height: 380px;
  }

  .adaptive-carousel-image2-height {
    height: 300px;
  }

  .adaptive-carousel-image1-height {
    height: 300px !important;
  }

  .carousel .control-dots {
    bottom: -10px !important;
  }
}

.height-48px {
  min-height: 48px;
}

.right-1p5em {
  right: 1.5em;
}

.input-box::-webkit-date-and-time-value {
  text-align: left;
}

.flex-align-items-end {
  display: flex;
  align-items: flex-end;
}

.input-box-disabled-padding-left-5px.disabled {
  padding-left: 5px;
}

.padding-right-4p7-em {
  padding-right: 4.7em;
}

.padding-right-0 {
  padding-right: 0;
}

.min-width-250px {
  min-width: 250px;
}

.input-box-border-bottom-default {
  border: none;
  border-bottom: 1px solid #757575;
}

.input-box:focus {
  outline: none;
}

.input-box:required {
  box-shadow: none;
}

.input-box-label {
  font-size: 16px;
  pointer-events: none;
  top: 55%;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
  text-transform: uppercase;
  transform: translateY(-50%);
  color: var(--secondaryColor);
}

.input-box-label.active {
  top: 10px;
  font-size: 10px;
  color: var(--secondaryColor);
}

.input-box-label.active.disabled {
  left: 5px;
}

.input-box-border-bottom {
  position: relative;
  display: block;
  width: 100%;
}

.input-box-border-bottom:before,
.input-box-border-bottom:after {
  content: "";
  height: 2px;
  width: 0;
  bottom: 0;
  position: absolute;
  transition: 0.2s ease all;
  background: transparent;
}

.input-box-border-bottom:before {
  left: 50%;
}

.input-box-border-bottom:after {
  right: 50%;
}

/* active state */
.input-box.active ~ .input-box-border-bottom:before,
.input-box.active ~ .input-box-border-bottom:after {
  width: 50%;
  background: var(--primaryColor) !important;
}

/* error state */
.input-box.error ~ .input-box-border-bottom:before,
.input-box.error ~ .input-box-border-bottom:after {
  width: 50%;
  background: #e64539 !important;
}

.transform-translateY-25-percentage {
  transform: translateY(-25%);
}

.top-50-percentage {
  top: 50%;
}

.min-width-1em {
  min-width: 1em;
}

.height-width-1em {
  height: 1em;
  width: 1em;
}

.margin-left-8px {
  margin-left: 8px;
}

.suffix-button-border {
  border: 1px solid #e5e5e5;
}

.border-radius-0p7em {
  border-radius: 0.7em;
}

.text-transform-uppercase {
  text-transform: uppercase;
}

.animated-height {
  transition: height 2s;
}

.min-width-138px {
  min-width: 138px;
}

.min-width-120px {
  min-width: 120px;
}

.circular-loader {
  width: 0.9em;
  height: 0.9em;
  background-color: #fff;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border: 2px solid var(--primaryColor);
  border-left-color: transparent;
  animation-name: spin;
  animation-duration: 0.7s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
  }
}

@media screen and (min-width: 576px) {
  .adaptive-font-size {
    font-size: 16px;
  }

  .adaptive-font-size.active {
    font-size: 10px;
    top: 12px;
  }
}

.otp-input-box-size {
  width: 48px;
  height: 48px;
}

.otp-input-box-size-small {
  width: 36px;
  height: 48px;
}

.max-width-54px {
  max-width: 54px;
}

.border-bottom-otp-box {
  border: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
  border-radius: 0;
  border-bottom: 1px solid var(--tertiaryColor);
}

.border-bottom-otp-box:focus {
  outline: none;
  border: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
  border-radius: 0;
  border-bottom: 1px solid var(--primaryColor);
}

.border-bottom-otp-box-error {
  outline: none;
  border: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
  border-radius: 0;
  border-bottom: 1px solid #e64539;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] { /* Firefox */
  -webkit-appearance: textfield; /* Safari and Chrome */
  appearance: textfield;
}

@media only screen and (max-width: 380px) {
  .otp-input-box-size {
    height: 38px;
    width: 38px;
  }
}

.filled-button-background {
  background: linear-gradient(139.67deg, #00a000 24.46%, #14f27a 100%);
  color: white;
}

.max-height-44px {
  min-height: 44px;
  max-height: 44px;
}

.bordered-button-background {
  border: 2px solid #00a000;
}

.disabled-button-background {
  background: #b2bff7;
  color: white;
}

.top-right {
  top: 80px;
  right: 16px;
}

.bottom-right {
  right: 16px;
  bottom: 16px;
}

.border-radius-16px {
  border-radius: 16px;
}

.floating-button-size {
  height: 48px;
  width: 48px;
}

.button-background-transparent {
  background: rgba(0, 0, 0, 0.5);
}

.dot-pulse {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #ffff;
  color: #ffff;
  box-shadow: 9999px 0 0 -5px #ffff;
  animation: dotPulse 1.5s infinite linear;
  animation-delay: 0.25s;
}

.dot-pulse::before,
.dot-pulse::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #ffff;
  color: #ffff;
}

.dot-pulse::before {
  box-shadow: 9984px 0 0 -5px #ffff;
  animation: dotPulseBefore 1.5s infinite linear;
  animation-delay: 0s;
}

.dot-pulse::after {
  box-shadow: 10014px 0 0 -5px #ffff;
  animation: dotPulseAfter 1.5s infinite linear;
  animation-delay: 0.5s;
}

.floating-button-active-icon-position-top-right {
  top: 0;
  right: 0;
}

@keyframes dotPulseBefore {
  0% {
    box-shadow: 9984px 0 0 -5px #ffff;
  }

  30% {
    box-shadow: 9984px 0 0 2px #ffff;
  }

  60%,
  100% {
    box-shadow: 9984px 0 0 -5px #ffff;
  }
}

@keyframes dotPulse {
  0% {
    box-shadow: 9999px 0 0 -5px #ffff;
  }

  30% {
    box-shadow: 9999px 0 0 2px #ffff;
  }

  60%,
  100% {
    box-shadow: 9999px 0 0 -5px #ffff;
  }
}

@keyframes dotPulseAfter {
  0% {
    box-shadow: 10014px 0 0 -5px #ffff;
  }

  30% {
    box-shadow: 10014px 0 0 2px #ffff;
  }

  60%,
  100% {
    box-shadow: 10014px 0 0 -5px #ffff;
  }
}

.dot-pulse-dark {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #cfcfcf;
  color: #cfcfcf;
  box-shadow: 9999px 0 0 -5px #cfcfcf;
  animation: dotPulseDark 1.5s infinite linear;
  animation-delay: 0.25s;
}

.dot-pulse-dark::before,
.dot-pulse-dark::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #cfcfcf;
  color: #cfcfcf;
}

.dot-pulse-dark::before {
  box-shadow: 9984px 0 0 -5px #cfcfcf;
  animation: dotPulseDarkBefore 1.5s infinite linear;
  animation-delay: 0s;
}

.dot-pulse-dark::after {
  box-shadow: 10014px 0 0 -5px #cfcfcf;
  animation: dotPulseDarkAfter 1.5s infinite linear;
  animation-delay: 0.5s;
}

@keyframes dotPulseDarkBefore {
  0% {
    box-shadow: 9984px 0 0 -5px #cfcfcf;
  }

  30% {
    box-shadow: 9984px 0 0 2px #cfcfcf;
  }

  60%,
  100% {
    box-shadow: 9984px 0 0 -5px #cfcfcf;
  }
}

@keyframes dotPulseDark {
  0% {
    box-shadow: 9999px 0 0 -5px #cfcfcf;
  }

  30% {
    box-shadow: 9999px 0 0 2px #cfcfcf;
  }

  60%,
  100% {
    box-shadow: 9999px 0 0 -5px #cfcfcf;
  }
}

@keyframes dotPulseDarkAfter {
  0% {
    box-shadow: 10014px 0 0 -5px #cfcfcf;
  }

  30% {
    box-shadow: 10014px 0 0 2px #cfcfcf;
  }

  60%,
  100% {
    box-shadow: 10014px 0 0 -5px #cfcfcf;
  }
}

@media screen and (min-width: 576px) {
  .top-right {
    top: 116px;
  }

  .adaptive-floating-button-size {
    height: 30px;
    width: 30px;
  }

  .bottom-right {
    right: 36px;
    bottom: 36px;
  }

  .dot-pulse {
    position: relative;
    left: -9999px;
    width: 15px;
    height: 15px;
    border-radius: 8px;
    background-color: #ffff;
    color: #ffff;
    box-shadow: 9999px 0 0 -5px #ffff;
    animation: dotPulse 1.5s infinite linear;
    animation-delay: 0.25s;
  }

  .dot-pulse::before,
  .dot-pulse::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    width: 15px;
    height: 15px;
    border-radius: 8px;
    background-color: #ffff;
    color: #ffff;
  }
}

.adaptive-floating-button-size {
  height: 30px;
  width: 30px;
}

.dot-pulse-primary {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #00a000;
  color: #00a000;
  box-shadow: 9999px 0 0 -5px #00a000;
  animation: dotPulsePrimary 1.5s infinite linear;
  animation-delay: 0.25s;
}

.dot-pulse-primary::before,
.dot-pulse-primary::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #00a000;
  color: #00a000;
}

.dot-pulse-primary::before {
  box-shadow: 9984px 0 0 -5px #00a000;
  animation: dotPulsePrimaryBefore 1.5s infinite linear;
  animation-delay: 0s;
}

.dot-pulse-primary::after {
  box-shadow: 10014px 0 0 -5px #00a000;
  animation: dotPulsePrimaryAfter 1.5s infinite linear;
  animation-delay: 0.5s;
}

@keyframes dotPulsePrimaryBefore {
  0% {
    box-shadow: 9984px 0 0 -5px #00a000;
  }

  30% {
    box-shadow: 9984px 0 0 2px #00a000;
  }

  60%,
  100% {
    box-shadow: 9984px 0 0 -5px #00a000;
  }
}

@keyframes dotPulsePrimary {
  0% {
    box-shadow: 9999px 0 0 -5px #00a000;
  }

  30% {
    box-shadow: 9999px 0 0 2px #00a000;
  }

  60%,
  100% {
    box-shadow: 9999px 0 0 -5px #00a000;
  }
}

@keyframes dotPulsePrimaryAfter {
  0% {
    box-shadow: 10014px 0 0 -5px #00a000;
  }

  30% {
    box-shadow: 10014px 0 0 2px #00a000;
  }

  60%,
  100% {
    box-shadow: 10014px 0 0 -5px #00a000;
  }
}

.checkmark__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: #4362EA;
    fill: none;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards
}

.checkmark {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: #fff;
    stroke-miterlimit: 10;
    margin: 10% auto;
    box-shadow: inset 0px 0px 0px #4362EA;
    animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both
}

.checkmark__check {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards
}

@keyframes stroke {
    100% {
        stroke-dashoffset: 0
    }
}

@keyframes scale {

    0%,
    100% {
        transform: none
    }

    50% {
        transform: scale3d(1.1, 1.1, 1)
    }
}

@keyframes fill {
    100% {
        box-shadow: inset 0px 0px 0px 30px #4362EA
    }
}
.black-dashed-border {
  border: 2px #757575 dashed;
}

.snackbar {
	visibility: hidden;
	min-width: 250px;
	position: fixed;
	z-index: 101;
	left: 50%;
	bottom: -6%;
	max-width: 360px;
	transition: transform 0.8s;
	transform: translateX(-50%);
}

.snackbar.show {
	visibility: visible;
	transform: translate(-50%, -250%);
}

.snackbar.fadeOut {
	visibility: visible;
	transform: translate(-50%, 250%);
}

.max-width-75-percentage {
	max-width: 75%;
}

.error-fallback-image-height {
	height: 156px;
}

@media screen and (min-width: 576px) {
	.error-fallback-image-height {
		height: 250px;
	}
}

