.carousel .control-dots {
  bottom: -14px !important;
}

.carousel .control-arrow {
  opacity: 1 !important;
}

.carousel .control-dots .dot {
  box-shadow: none !important;
}

.carousel .control-prev.control-arrow {
  top: 50%;
  width: 30px;
  height: 40px;
  left: 24px;
  bottom: 50%;
}

.carousel .control-next.control-arrow {
  top: 50%;
  width: 30px;
  height: 40px;
  right: 24px;
  bottom: 50%;
}

.carousel .control-prev.control-arrow:before {
  border-right: 8px solid white !important;
}

.carousel .control-next.control-arrow:before {
  border-left: 8px solid white !important;
}

.qr-loader {
  margin-top: 0.2em;
  margin-bottom: 0.2em;
  text-indent: -9999em;
  width: 1.3em;
  height: 1.3em;
  border-radius: 50%;
  background: #4362ea;
  background: -moz-linear-gradient(left, #4362ea 10%, rgba(0, 200, 128, 0) 42%);
  background: -webkit-linear-gradient(
    left,
    #4362ea 10%,
    rgba(0, 200, 128, 0) 42%
  );
  background: -o-linear-gradient(left, #4362ea 10%, rgba(0, 200, 128, 0) 42%);
  background: -ms-linear-gradient(left, #4362ea 10%, rgba(0, 200, 128, 0) 42%);
  background: linear-gradient(to right, #4362ea 10%, rgba(0, 200, 128, 0) 42%);
  position: relative;
  -webkit-animation: load3 1.4s infinite linear;
  animation: load3 1.4s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.qr-loader:before {
  width: 50%;
  height: 50%;
  background: #4362ea;
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
}

.qr-loader:after {
  background: white;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: "";
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

@-webkit-keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.adaptive-border-radius-default {
  border-radius: 8px;
}

.adaptive-padding-main {
  padding: 24px;
}

.adaptive-parent-height {
  width: 100%;
  height: fit-content;
}

.adaptive-login-section-width-height {
  width: 30%;
}

.adaptive-carousel-section-width-height {
  width: 70%;
  height: fit-content;
}

.adaptive-carousel-image1-height {
  height: 100%;
}

.adaptive-carousel-image2-height {
  height: 100%;
}

.adaptive-carousel-width {
  width: 90%;
}

.adaptive-carousel-height {
  height: 75%;
}

.adaptive-flex {
  display: flex;
}

.adaptive-width-50-percentage {
  width: 50%;
}
.p1-adaptive-font-size {
  font-size: 20px;
}

.p-adaptive-font-size {
  font-size: 16px;
}

.carousel .control-dots {
  bottom: 0px !important;
}

.adaptive-map-width {
  width: 55%;
}

@media only screen and (max-width: 767px) {
  .adaptive-parent-height {
    height: fit-content;
  }

  .adaptive-border-radius-default {
    border-radius: 0px;
  }

  .adaptive-padding-main {
    padding: 0px;
  }

  .adaptive-flex {
    display: block;
  }

  .adaptive-map-width {
    width: 100%;
  }
  .adaptive-padding-top {
    padding-top: 24px;
  }

  .p1-adaptive-font-size {
    font-size: 18px;
  }
  .p-adaptive-font-size {
    font-size: 14px;
  }

  .adaptive-login-section-width-height {
    width: 100%;
  }

  .adaptive-width-50-percentage {
    width: 100%;
  }

  .adaptive-carousel-section-width-height {
    width: 100%;
    height: fit-content;
    min-height: fit-content;
  }

  .adaptive-align {
    justify-content: center;
  }

  .adaptive-carousel-width {
    width: 90%;
  }

  .adaptive-map-width {
    width: 100%;
  }

  .adaptive-carousel-height {
    height: 100%;
  }

  .adaptive-carousel-image2-height {
    height: 320px;
  }

  .adaptive-carousel-image1-height {
    height: 470px !important;
  }
}

@media only screen and (max-width: 1440px) and (min-width: 1400px) and (min-height: 700px) {
  .adaptive-carousel-width {
    width: 90%;
  }
  .adaptive-carousel-height {
    height: 380px;
  }

  .adaptive-carousel-image2-height {
    height: 300px;
  }

  .adaptive-carousel-image1-height {
    height: 300px !important;
  }

  .carousel .control-dots {
    bottom: -10px !important;
  }
}
